import { RichText } from 'prismic-reactjs';
import React, {useEffect, useRef} from 'react';
import {gsap} from "gsap";
import { ServiceListWrapper, ListWrapper } from './styles';
import { ServiceItem } from './ServiceItem';

export const ServiceList = ({ heading, subHeading, description, fields }) => {

	const Items = [ useRef(), useRef(), useRef(), useRef() ];

	useEffect(() => {
		let tl = gsap.timeline({repeat: -1, yoyo: true});
		tl.to(Items[0].current, 0.5,{css:{scaleX:1.02, scaleY:1.02}});
	}, []);

	return (
		<ServiceListWrapper>
			{!!heading && <RichText render={heading} />}
			{!!subHeading && <RichText render={subHeading} />}
			{!!description && <RichText render={description} />}
			{!!fields && (
				<ListWrapper>
					{fields.map((item, i) => (
						<li ref={Items[i]}>
							<ServiceItem
								keyCode={`serviceItem-${i}`}
								heading={item.heading}
								subHeading={item.subHeading}
								description={item.description}
								image={item.image}
								ctaAction={item.cta_action._meta.uid}
							/>
						</li>
					))}
				</ListWrapper>
			)}
		</ServiceListWrapper>
	);
};
