import React from 'react';
import { ImageBlockWrapper } from './styles';
import Img from 'gatsby-image';

export const ImageBlock = ({ fluid, type }) => {
	return (
		<ImageBlockWrapper type={type}>
			<Img fluid={fluid} imgStyle={{ objectFit: 'contain' }} />
		</ImageBlockWrapper>
	);
};
