import styled from 'styled-components';

export const PricingListWrapper = styled.section`
	width: 100%;
	height: 100%;

	> p {
		margin: 30px auto;

		> a {
			color: white;
		}
	}
`;

export const PackageList = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
	grid-gap: 2em;
	width: 100%;
	max-width: 1200px;
	height: fit-content;
	padding: 30px 0;
	margin: 0 auto;

	@media (max-width: 350px) {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	}
`;
