import styled from 'styled-components';

export const BlogListWrapper = styled.div`
	width: 100%;
	max-width: 900px;
	height: 100%;
	margin: 20px auto 30px auto;
	list-style-type: none;
	padding: 20px;
	text-align: left;
	background-color: rgba(0, 0, 0, 0.15);
	border-radius: 7px;
`;

export const Info = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	width: 100%;
	max-width: 800px;
	height: fit-content;
	padding: 20px;
	border-radius: 5px;

	@media (max-width: 750px) {
		padding: 20px 5px;
	}

	> h2 {
		font-family: "Noto Sans JP";
		margin-bottom: 15px;
		line-height: 50px;
	}

	> p {
		margin-bottom: 20px;
		padding: 0 20px;
	}

	> a {
		text-decoration: none;
	}
`;

export const BlogItem = styled.li`
	display: flex;
	align-items: center;
	height: fit-content;
	border-bottom: 2px solid white;

	@media (max-width: 750px) {
		flex-direction: column;
	}

	> .gatsby-image-wrapper {
		width: 100%;
		max-width: 250px;
		height: 100%;
		border: 3px solid white;

		@media (max-width: 750px) {
			max-width: 500px;
			margin-bottom: 20px;
		}
	}
`;
