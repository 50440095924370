import React, { useState } from 'react';
import { HeaderWrapper, Branding, NavLinks, Hamburger } from './styles';
import Link from 'utils/link';
import Img from 'gatsby-image';
import { FaBars, FaTimes } from 'react-icons/fa';
import { navigate } from 'gatsby';

export const Header = ({ image, branding, links }) => {
	const [ toggle, setToggle ] = useState(false);

	links = [
		// { label: 'Pricing', link: { _meta: { uid: 'pricing' } } },
		{ label: 'Portfolio', link: { _meta: { uid: 'portfolio' } } },
		...links,
		{ label: 'Blog', link: { _meta: { uid: 'blog' } } }
	];

	const handleClick = (e) => {
		e.currentTarget.blur();
	};
	return (
		<HeaderWrapper toggle={toggle}>
			{/* <Link to="/" onClick={(e) => handleClick(e)}> */}
			<Branding
				onClick={(e) => {
					handleClick(e);
					setToggle(false);
					navigate('/');
				}}>
				<Img fixed={image.fixed} objectFit="contain" alt="Branding" />
				<h6>{branding}</h6>
			</Branding>
			{/* </Link> */}
			<NavLinks toggle={toggle}>
				{links.map((link, i) => (
					<li
						key={`navLink-${i}`}
						onClick={(e) => {
							setToggle((e) => !e);
							navigate(`/${link.link._meta.uid}`);
						}}>
						<Link to={`/${link.link._meta.uid}`}>{link.label}</Link>
					</li>
				))}
			</NavLinks>
			<Hamburger
				onClick={(e) => {
					setToggle((e) => !e);
				}}>
				{toggle ? <FaTimes /> : <FaBars />}
			</Hamburger>
		</HeaderWrapper>
	);
};
