import React from "react";
import {FormButton} from "../Button";
import RichText from "utils/richText";
import {Container, FormWrapper, Input, TextArea} from "./styles";

export const Form = ({name, heading, subHeading, description, fields, action}) => {
  return (
    <Container>
      {!!heading?.[0]?.text && <RichText render={heading} />}
			{!!subHeading?.[0]?.text && <RichText render={subHeading} />}
			{!!description?.[0]?.text && <RichText render={description} />}
      <FormWrapper
        name={name}
        method="POST"
        data-netlify="true"
		data-netlify-honeypot="bot-field"
        action={`/${action._meta.uid}`}>
        <input type="hidden" name="form-name" value={name}></input>
		<div hidden>
			<input name="bot-field"/>
		</div>
        {fields.map((field, i) => {
					if (field.field_type === 'textarea') {
						return (
							<div key={i}>
								<label for={field.field_name}>{field.field_name}</label>
								<TextArea
									name={field.field_name}
									id={field.field_name}
									cols="30"
									rows="10"
									required={field.required === 'yes'}
									placeholder={field.field_name}
								/>
							</div>
						);
					} else {
						return (
							<div key={i}>
								<label for={field.field_name}>{field.field_name}</label>
								<Input
									name={field.field_name}
									id={field.field_name}
									type={field.field_type}
									required={field.required === 'yes'}
									placeholder={field.field_name}
								/>
							</div>
						);
					}
				})}
				<FormButton fullWidth type="submit">Submit</FormButton>
      </FormWrapper>
    </Container>
  )
}