import React from 'react';
import { ServiceItemWrapper, InfoWrapper } from './styles';
import Img from 'gatsby-image';
import RichText from 'utils/richText';
import { Link } from 'gatsby';

export const ServiceItem = ({
	heading,
	subHeading,
	description,
	ctaLabel,
	ctaAction,
	image,
	keyCode
}) => {
	return (
		<Link
		key={keyCode}
		to={ctaAction}
		style={{ color: 'white', textDecoration: 'none' }}>
		<ServiceItemWrapper>
				<img src={image.url} alt={`service-${heading}`}/>
				<InfoWrapper>
					{!!heading && <RichText render={heading} />}
					{!!subHeading && <RichText render={subHeading} />}
					{!!description && <RichText render={description} />}
				</InfoWrapper>
			</ServiceItemWrapper>
		</Link>
	);
};
