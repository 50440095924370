import React from 'react';
import { Link } from 'gatsby';

const CustomLink = ({ to, children }) => {
	const handleClick = (e) => {
		e.currentTarget.blur();
	};

	return (
		<Link to={to} onClick={handleClick}>
			{children}
		</Link>
	);
};

export default CustomLink;
