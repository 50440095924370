import React from 'react';
import { BlogListWrapper, BlogItem, Info } from './styles';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { Button } from 'components';

export const BlogList = ({ posts }) => {
	return (
		<BlogListWrapper>
			{posts.map((post) => (
				<BlogItem>
					<Info>
						<h2>{post.node.page_title[0].text}</h2>
						<p>{post.node.seo_description}</p>
						{/* <Link to={`/blog/${post.node._meta.uid}`}> */}
						<Button to={`/blog/${post.node._meta.uid}`}>Read More</Button>
						{/* </Link> */}
					</Info>
					<Img fluid={post.node.imageSharp.childImageSharp.fluid} />
				</BlogItem>
			))}
		</BlogListWrapper>
	);
};
