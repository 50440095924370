import React from 'react';
import { TestimonialWrapper, Info } from './styles';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import Img from 'gatsby-image';

export const Testimonial = ({ testimonial, author, image }) => {
	return (
		<TestimonialWrapper>
			<Info>
				<p>
					<FaQuoteLeft /> {testimonial} <FaQuoteRight />
				</p>
				<p>
					{author.split('-')[0]} - <strong>{author.split('-')[1]}</strong>
				</p>
			</Info>
			<Img
				fluid={image.childImageSharp.fluid}
				imgStyle={{ objectFit: 'contain' }}
			/>
		</TestimonialWrapper>
	);
};
