import React from 'react';
import { SplitTextImageWrapper, InfoWrapper, Container } from './styles';
import Img from "gatsby-image";
import RichText from 'utils/richText';

export const SplitTextImage = ({
	heading,
	subHeading,
	description,
	ctaLabel,
	ctaAction,
	image
}) => {
	return (
		<SplitTextImageWrapper>
			<Container>
				<InfoWrapper>
					{!!heading && <RichText render={heading} />}
					{!!subHeading && <RichText render={subHeading} />}
					{!!description && <RichText render={description} />}
				</InfoWrapper>
				<img src={image.url} alt={heading}/>
			</Container>
		</SplitTextImageWrapper>
	);
};
