import styled from 'styled-components';

export const FooterWrapper = styled.footer`
	width: 100%;
	height: 50px;
	background-color: var(--secondary-color);
	color: white;
	padding: 0 40px;
	display: flex;
	flex-shrink: 0;
	align-items: center;

	> p {
		margin-left: 0;
	}
	
	p:nth-child(2) {
		width: fit-content;
		white-space: nowrap;
		padding-right: 20px;
	}
`;
