import styled from 'styled-components';

export const Container = styled.div`
	padding: 40px 0;
	width: 100%;
	height: 100%;
	color: white;

	h2 {
		font-family: "Noto Sans JP";
		margin-bottom: 50px;
		line-height: 2rem;

		@media (max-width: 500px) {
			line-height: 2rem;
		}
	}
`;

export const FormWrapper = styled.form`
	width: 100%;
	height: 100%;
	max-width: 800px;
	margin: 30px auto;

	@media (max-width: 850px) {
		max-width: 600px;
	}

	label {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
`;

export const Input = styled.input`
	width: 100%;
	margin-bottom: 10px;
	padding: 5px;
	border-radius: 5px;
`;

export const TextArea = styled.textarea`
	width: 100%;
	padding: 5px;
	border-radius: 5px;
`;
