import styled from 'styled-components';

export const ItemWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: ${(props) => (props.height ? `${props.height}px`: "auto")};
	min-height: 190px;
	max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px`: "400px")};
	margin: 0 auto;
	background-color: var(--primary-color);
	border: 3px solid white;
	border-radius: 5px;
	cursor: ${(props) => (props.basic !== null ? 'pointer' : 'auto')};

	&:hover {
		border: 7px solid white;
	}

	@media (max-width: 500px) {
		max-width: 100%;
	}

	> div {
		flex-grow: 1;
	}
`;

export const Info = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: flex-end;

	> a {
		padding: 10px;
		background-color: white;
		border-top-style: 3px solid var(--secondary-color);
		border-bottom-style: 3px solid var(--secondary-color);
		width: 100%;
		font-size: 22px;
		color: var(--secondary-color);
		text-decoration: none;
	}
`;

export const ImageContainer = styled.div`
	width: ${(props) => (props.maxWidth ? `${props.maxWidth}px`: "400px")};
	height: ${(props) => (props.height ? `${props.height}px`: "auto")};
	margin: 0 auto;

	@media (max-width: 970px) {
		width: ${(props) => (props.maxWidth ? `${props.maxWidth/1.2}px`: "400px")};
		height: ${(props) => (props.height ? `${props.height/1.2}px`: "auto")};
	}

	@media (max-width: 800px) {
		width: ${(props) => (props.maxWidth ? `${props.maxWidth/1.4}px`: "400px")};
		height: ${(props) => (props.height ? `${props.height/1.4}px`: "auto")};
	}

	@media (max-width: 630px) {
		width: auto;
		height: ${(props) => (props.height ? `${props.height/1.7}px`: "auto")};
	}
`;
