import styled from 'styled-components';

export const SplitTextImageWrapper = styled.div`
	width: 100%;
	height: 100%;
	padding: 40px 0px;
	color: white;
	margin: 0 auto;
`;

export const InfoWrapper = styled.div`
	margin: 0 auto;
	text-align: left;

	@media (max-width: 500px) {
		text-align: center;
	}

	h2 {
		margin-bottom: 20px;
		font-family: "Noto Sans JP";
		font-size: 2.5rem;

		@media (max-width: 500px) {
			line-height: 3rem;
		}
	}

	h3 {
		line-height: 4rem;

		@media (max-width: 500px) {
			line-height: 2rem;
			margin-bottom: 10px;
		}
	}
`;

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;

	@media (max-width: 1050px) {
		flex-direction: column;
	}

	>img {
		width: 100%;
		max-width: 450px;
		margin: 0 auto;

		@media (max-width: 1050px) {
			max-width: 400px;
		}
	}
`;
