import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

const fullWidthStyles = ({ fullWidth }) => {
	if (fullWidth) {
		return css`
			display: block;
			width: 100%;
			padding: 10px 20px;
		`;
	}
};

const buttonStyles = () => {
	return css`
		text-align: center;
		width: fit-content;
		margin: 0 auto 20px auto;
		height: 50px;
		padding: 10px 20px;
		cursor: pointer;
		box-shadow: none;
		font-weight: bold;
		white-space: nowrap;
		background-color: var(--secondary-color);
		display: flex;
		align-items: center;
		color: white;
		text-decoration: none;
		border: 1px solid white;
		border-radius: 5px;
		${fullWidthStyles};

		&:hover {
			background-color: white;
			color: var(--secondary-color);
			border: 1px solid var(--secondary-color);
		}
	`;
};

export const FormButton = styled.button`${buttonStyles};`;

export const Button = styled(Link)`${buttonStyles}`;
