import React from 'react';
import { FooterWrapper } from './styles';
import {
	FaExclamationTriangle,
	FaFacebookSquare,
	FaTwitterSquare,
	FaInstagramSquare
} from 'react-icons/fa';
import {navigate} from "gatsby";

export const Footer = ({ company, links }) => {
	const getIcon = (name, size, key) => {
		switch (name) {
			case 'facebook':
				return <FaFacebookSquare size={size} key={`${key}- ${name}`} />;
			case 'twitter':
				return <FaTwitterSquare size={size} key={`${key}- ${name}`} />;
			case 'instagram':
				return <FaInstagramSquare size={size} key={`${key}- ${name}`} />;
			default:
				return <FaExclamationTriangle size={size} key={`${key}- ${name}`} />;
		}
	};

	return (
		<FooterWrapper>
			<p>
				&copy;{company} {new Date().getFullYear()}
			</p>
			<p onClick={()=> navigate("/privacy-policy")} style={{cursor: "pointer"}}>
				Privacy Policy
			</p>
			{links.map((link, key) => getIcon(link.social_media_type, '2em', key))}
		</FooterWrapper>
	);
};
