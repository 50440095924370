import styled from 'styled-components';

export const ServiceItemWrapper = styled.div`
	margin: 10px auto;
	padding: 10px 0 10px 20px;
	border: 1px solid white;
	background-color: var(--primary-color);
	border-radius: 5px;
	width: 100%;
	max-width: 700px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: space-between;
	cursor: pointer;

	&:hover {
		background-color: #117cc1;
		border: 1px solid var(--primary-color);
	}

	&:nth-child(even) {
		flex-direction: row-reverse;
	}

	> img {
		flex: 1 1 0.5em;
		margin: auto;
		padding-right: 20px;
		max-width: 125px;
		width: 125px;
		max-height: 125px;
		min-width: 80px;
		height: 80px;
		min-height: 80px;

		/* @media (max-width: 500px) {
			> img,
			picture {
				padding-right: 20px;
			}
		} */
	}
`;

export const InfoWrapper = styled.div`
	flex: 2 2 20em;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	padding: 2px;
	text-align: left;

	> h2 {
		margin-bottom: 15px;
		text-decoration: underline;
		line-height: 2rem;
		font-family: "Noto Sans JP";
	}
`;
