import styled from 'styled-components';

export const TestimonialWrapper = styled.div`
	margin: 0 auto;
	padding: 20px 0;
	width: 100%;
	max-width: 1400px;
	height: fit-content;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-around;
	color: white;

	> .gatsby-image-wrapper {
		flex-grow: 1;
		width: 100%;
		max-width: 250px;
		height: 100%;
		max-height: 250px;
		border: 1px solid var(--background-color);
		background-color: var(--background-color);
		border-radius: 10px;

		@media (max-width: 1200px) {
			width: 30vw;
			height: 30vw;
		}
	}
`;

export const Info = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 800px;

	> p:first-child {
		line-height: 40px;
		padding-bottom: 20px;
	}

	> p:last-child {
		width: fit-content;
		padding: 10px 20px;
		border-top: 1px solid white;
	}
`;
