import React from 'react';
import { PortfolioWrapper } from './styles';
import { PortfolioItem } from './PortfolioItem';

export const PortfolioGallery = ({ fields }) => {
	return (
		<PortfolioWrapper>
			{fields.map((item) => {
				const image = item.imageSharp.childImageSharp.fluid;
				const maxWidth = +image.sizes.split(" ")[3].split("px")[0]/2;
				const height = maxWidth / image.aspectRatio;
				return(
					<PortfolioItem
						image={item.imageSharp.childImageSharp.fluid}
						link={item.link}
						maxWidth={maxWidth}
						height={height}
					/>
				)
			})}
		</PortfolioWrapper>
	);
};
