import styled from 'styled-components';

export const HeroWrapper = styled.div`
	background-color: red;
	padding: 0;
	width: 100%;
	height: 100%;
	min-height: 85vh;
	text-align: center;
	position: relative;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 2;

	@media (max-width: 800px) {
		padding: 10px;
	}

	> .gatsby-image-wrapper {
		width: 100%;
		max-width: 250px;
		height: 100%;
		max-height: 250px;
		margin-bottom: 20px;

		@media (max-width: 800px) {
			max-width: 30vw;
			max-height: 30vw;
		}
	}

	> h1 {
		font-family: "Orbitron";
		font-weight: 900;
	}

	> h1,
	> p {
		z-index: 2;
		margin-bottom: 20px;
	}

	a {
		z-index: 2;
	}
`;
