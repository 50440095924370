import React from "react";
import {ModalWrapper, IconWrapper, ContentWrapper} from "./style";
import {FaTimes} from "react-icons/fa";

export default function Modal({children, show, onClose}) {

    const handleClose = (e) => {
        e.preventDefault();
        onClose(false);
    }

    return (
        <>
            {show && (
                <ModalWrapper>
                    <IconWrapper onClick={handleClose}>
                        <FaTimes/>
                    </IconWrapper>
                    <ContentWrapper>
                        {children}
                    </ContentWrapper>
                </ModalWrapper>
            )}
        </>
    )
}
