import styled from "styled-components";

export const ModalWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    background-color: rgba(1,1,1,0.4);
    z-index: 100;
    padding: 20px;
`;

export const IconWrapper = styled.div`
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 2rem;
    z-index: 100;
    cursor: pointer;
    background-color: rgba(0,0,0,0.5);
`;

export const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 0;
`;