import React from 'react';
import { HeroWrapper } from './styles';
import { Button } from 'components/Button';
import Img from 'gatsby-image';
import RichText from 'utils/richText';
import Background from "./background2";

export const Hero = ({ logo, heading, subHeading, description, ctaLabel, ctaAction }) => {
	return (
		<>
		<HeroWrapper>
		<Background/>
			{!!logo && <Img fluid={logo} />}
			{!!heading && <RichText render={heading} />}
			{!!subHeading && <RichText render={subHeading} />}
			{!!description && <RichText render={description} />}
			{!!ctaLabel && <Button to={ctaAction}>{ctaLabel}</Button>}
		</HeroWrapper>
		</>
	);
};
