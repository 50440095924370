import styled from 'styled-components';

export const ServiceListWrapper = styled.div`
	width: 100%;
	height: fit-content;
	padding: 40px 0;
	background-color: var(--background-color);
	color: white;
	text-align: center;

	> h1 {
		font-family: "Noto Sans JP";
		font-size: 2.5rem;
	}
`;

export const ListWrapper = styled.ul`
	padding: 30px 0;
	margin: 0 auto;
	width: 100%;
	max-width: 1500px;
	height: fit-content;
	list-style-type: none;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
	grid-column-gap: 20px;
	grid-row-gap: 20px;

	@media (max-width: 560px) {
		grid-template-columns: 1fr;
	}
`;
