import React from "react"
import PropTypes from "prop-types"
import {Header, Footer} from "components";
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components";

import "./layout.css"

const MainWrapper = styled.main`
  background-color: var(--background-color);
  width: 100%;
  height: fit-content;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          company
          title
        }
      }
      prismic {
        allNavigations {
          edges {
            node {
              branding
              branding_image
              branding_imageSharp {
                childImageSharp {
                  fixed(width: 70, height: 70) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
              navigation_links {
                label
                link {
                  ... on PRISMIC_Page {
                    _meta {
                      uid
                    }
                  }
                }
              }
              social_links {
                link
                social_media_type
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
        <Header image={data.prismic.allNavigations.edges[0].node.branding_imageSharp.childImageSharp} branding={data.prismic.allNavigations.edges[0].node.branding} links={data.prismic.allNavigations.edges[0].node.navigation_links}/>
        <MainWrapper>{children}</MainWrapper>
        <Footer company={data.site.siteMetadata.company} links={data.prismic.allNavigations.edges[0].node.social_links}/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
