import styled from 'styled-components';

export const PackageCard = styled.div`
	width: 100%;
	max-width: 350px;
	min-height: 600px;
	height: fit-content;
	max-height: 600px;
	background-color: var(--secondary-color);
	text-align: center;
	color: white;
	border: 5px solid var(--secondary-color);
	border-radius: 10px;
	margin: 0 auto;
	-webkit-box-shadow: 0px 10px 26px -1px rgba(100, 100, 100, 1);
	-moz-box-shadow: 0px 10px 26px -1px rgba(100, 100, 100, 1);
	box-shadow: 0px 10px 26px -1px rgba(100, 100, 100, 1);
	display: flex;
	flex-direction: column;

	@media (max-width: 500px) {
		min-height: fit-content;
	}

	&:nth-child(2) {
		border: 5px solid var(--primary-color);
	}

	> div {
		padding: 20px;

		> h2 {
			min-height: 60px;
		}

		> p {
			font-size: 1rem;
		}
	}

	> ul {
		list-style-type: none;
		margin: 0;
		padding: 0 10px;
		flex-grow: 1;
		height: 100%;
	}

	h2,
	h3,
	li {
		display: flex;
		align-items: center;
	}

	h2,
	h3 {
		justify-content: center;
	}

	> div {
		> h2 {
			color: white;
			min-height: 60px;
			height: 60px;

			> strong {
				font-family: "Noto Sans JP";
			}
		}

		> h3 {
			> strong {
				font-family: "Noto Sans JP";
			}
		}
	}

	> h3 {
		height: 80px;
		margin-bottom: 10px;
		padding: 0 0 10px 0;
		min-height: 80px;
		height: fit-content;
		background-color: rgba(0, 0, 0, 0.2);
		/* background-color: var(--primary-color); */
	}

	/* li {
		width: 100%;
		margin: 0;
		border-bottom: 1px solid var(--secondary-color);
		font-size: 1.2rem;
		padding: 10px;
		text-align: left;
		cursor: pointer;

		&:after {
			content: " ?";
			margin-left: auto;
			font-size: 18px;
		}

		&:hover {
			background-color: rgba(0, 0, 0, 0.2);
			text-decoration: underline;
		}
	} */

	a {
		color: white;
		text-decoration: none;
	}

	li:last-of-type {
		border: none;
	}

	h2 strong {
		color: #1996e6;
		font-size: 1.5rem;
	}

	strong {
		font-size: 2.5rem;
	}
`;

export const Item = styled.li`
	width: 100%;
	margin: 0;
	font-size: 1.2rem;
	padding: 10px;
	text-align: left;

	${(props) =>
		typeof props.link === 'string'
			? `
			cursor: pointer;
			border-bottom: 1px solid var(--secondary-color);

			&:after {
				content: " ?";
				margin-left: auto;
				font-size: 18px;
			}

			&:hover {
				text-decoration: underline;
			}`
			: ''};

	&:hover {
		background-color: rgba(0, 0, 0, 0.2);
	}
`;
