import React, {useState} from 'react';
import { ItemWrapper, Info, ImageContainer } from './styles';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import { Link, navigate } from 'gatsby';
import Modal from "../../Modal";

export const PortfolioItem = ({ image, link, maxWidth, height }) => {
	const [showModal, setShowModal] = useState(false);

	const handleClick = () => {
		if(link){
			navigate(link._meta.uid);
		}else{
			setShowModal(true);
		}
	}

	console.log(image);

	return (
		<>
		<Modal show={showModal} onClose={() => setShowModal(false)}>
			<ImageContainer maxWidth={maxWidth*2} height={height*2}>
				<Img fluid={image} />
			</ImageContainer>
		</Modal>
		<ItemWrapper basic={link} onClick={handleClick} maxWidth={maxWidth} height={height}>
			<BackgroundImage fluid={image}/>
			<Info>
				{!!link &&<Link to={link?._meta?.uid}>{link?.page_title[0]?.text}</Link>}
			</Info>
		</ItemWrapper>
		</>
	);
};
