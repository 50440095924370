module.exports = [{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"DWD-Site","previews":false,"pages":[{"type":"Page","match":"/:uid","component":"/opt/build/repo/src/templates/page.js"},{"type":"Blog","match":"/blog/:uid","component":"/opt/build/repo/src/templates/blog.js"},{"type":"Portfolio_Piece","match":"/portfolio/:uid","component":"/opt/build/repo/src/templates/portfolio.js"}],"sharpKeys":[{}],"omitPrismicScript":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-143823723-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Discovery Web Design","short_name":"DWD","start_url":"/","background_color":"#2d2828","theme_color":"#2d2828","display":"minimal-ui","icon":"src/images/Logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7f1262d930e1670c212dc0284b173653"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
