import styled from 'styled-components';

export const ImageBlockWrapper = styled.div`
	width: 100%;
	height: fit-content;
	margin: 0 auto;

	> .gatsby-image-wrapper {
		margin: 0 auto;
		width: ${(props) => (props.type === 'featured' ? '100%' : '65%')};
		height: fit-content;
		margin-bottom: 30px;
		border: ${(props) => (props.type === 'featured' ? '10px solid white' : 'none')};
	}
`;
