import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

const CanvasWrapper = styled.canvas`
	position: absolute;
	width: 100%;
	height: 100%;
	background: radial-gradient(#888 0%, var(--primary-color) 27%);
	/* background-color: black; */
	z-index: 0;

	@media (max-width: 800px) {
		background: radial-gradient(#888 0%, var(--primary-color) 77%);
	}
`;

const Canvas = () => {
	const canvas = useRef(null);

	useEffect(() => {
		const ctx = canvas.current.getContext('2d');
		ctx.canvas.width = window.innerWidth;
		ctx.canvas.height = window.innerHeight;
		let particleArray;

		let mouse = {
			x      : null,
			y      : null,
			radius : ctx.canvas.height / 100 * (ctx.canvas.width / 100)
		};

		const mouseMove = (event) => {
			if (window.pageYOffset === 0) {
				mouse.x = event.x;
				mouse.y = event.y;
			}
		};
		window.addEventListener('mousemove', mouseMove);

		//Particle Class
		function Particle(x, y, dirX, dirY, size, color) {
			this.x = x;
			this.y = y;
			this.dirX = dirX;
			this.dirY = dirY;
			this.size = size;
			this.color = color;
		}
		//draw method
		Particle.prototype.draw = function() {
			ctx.beginPath();
			ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);
			ctx.fillStyle = this.color;
			ctx.fill();
		};

		Particle.prototype.update = function() {
			if (this.x + this.size > window.innerWidth || this.x - this.size < 0) {
				this.dirX = -this.dirX;
			}
			if (this.y + this.size > window.innerHeight || this.y - this.size < 0) {
				this.dirY = -this.dirY;
			}

			let dx = mouse.x - this.x;
			let dy = mouse.y - this.y;
			let distance = Math.sqrt(dx * dx + dy * dy);
			if (distance < mouse.radius + this.size) {
				if (mouse.x < this.x && this.x < ctx.canvas.width - this.size * 10) {
					this.x += 10;
				}
				if (mouse.x > this.x && this.x > this.size * 10) {
					this.x -= 10;
				}
				if (mouse.y < this.y && this.y < ctx.canvas.height - this.size * 10) {
					this.y += 10;
				}
				if (mouse.y > this.y && this.y > this.size * 10) {
					this.y -= 10;
				}
			}

			this.x += this.dirX;
			this.y += this.dirY;
			this.draw();
		};

		function init() {
			let speed = 5;
			particleArray = [];
			let numberOfParticles = ctx.canvas.width / 40;
			for (let i = 0; i < numberOfParticles * 2; i++) {
				let size = Math.random() * 2 + 1;
				let x =
					Math.random() * (window.innerWidth - size * 2 - size * 2) + size * 2;
				let y =
					Math.random() * (window.innerHeight - size * 2 - size * 2) + size * 2;
				let directionX = Math.random() * speed - 2.5;
				let directionY = Math.random() * speed - 2.5;
				let color = '#ffffff';

				particleArray.push(
					new Particle(x, y, directionX, directionY, size, color)
				);
			}
		}

		function animate() {
			requestAnimationFrame(animate);
			ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);

			for (let i = 0; i < particleArray.length; i++) {
				particleArray[i].update();
			}
			connect();
		}

		function connect() {
			let opacityValue = 1;
			for (let a = 0; a < particleArray.length; a++) {
				for (let b = 0; b < particleArray.length; b++) {
					let distance =
						(particleArray[a].x - particleArray[b].x) *
							(particleArray[a].x - particleArray[b].x) +
						(particleArray[a].y - particleArray[b].y) *
							(particleArray[a].y - particleArray[b].y);
					if (distance < 80000) {
						opacityValue = 0.3 - distance / 70000;
						ctx.strokeStyle = `rgba(255,255,255,${opacityValue})`;
						ctx.lineWidth = 3;
						ctx.beginPath();
						ctx.moveTo(particleArray[a].x, particleArray[a].y);
						ctx.lineTo(particleArray[b].x, particleArray[b].y);
						ctx.stroke();
					}
				}
			}
		}

		const resize = () => {
			ctx.canvas.width = window.innerWidth;
			ctx.canvas.height = window.innerHeight;
			mouse.radius = canvas.height / 80 * (canvas.height / 80);
			init();
		};
		window.addEventListener('resize', resize);

		const mouseOut = () => {
			mouse.x = undefined;
			mouse.y = undefined;
		};
		window.addEventListener('mouseout', mouseOut);

		init();
		animate();

		return () => {
			window.removeEventListener('mouseout', mouseOut);
			window.removeEventListener('resize', resize);
			window.removeEventListener('mousemove', mouseMove);
			particleArray = [];
		};
	}, []);

	return <CanvasWrapper key={'canvas-1'} ref={canvas} />;
};

export default Canvas;
