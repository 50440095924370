import styled from 'styled-components';

export const HeaderWrapper = styled.header`
	width: 100%;
	height: 80px;
	min-height: 80px;
	color: white;
	background-color: var(--secondary-color);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 40px 0 20px;
	z-index: 6;
	position: relative;

	a {
		text-decoration: none;
		color: inherit;
	}

	@media (max-width: 800px) {
		position: ${(props) => (props.toggle ? 'fixed' : 'relative')};
		flex-direction: column;
		padding: 10px 0;
	}
`;

export const Branding = styled.div`
	display: flex;
	flex-grow: 1;
	align-items: center;
	padding-left: 100px;
	max-width: 600px;
	cursor: pointer;

	> h6 {
		font-family: "Orbitron";
		font-weight: 900 !important;
	}

	@media (max-width: 800px) {
		padding: 0;
		align-self: center;
		width: 100%;
		max-width: 250px;
		margin: 0 auto;
	}

	@media (max-width: 500px) {
		margin-right: 50px;
		margin-left: auto;
	}

	@media (max-width: 380px) {
		margin-right: 5px;
		margin-left: auto;
	}

	> img,
	> .gatsby-image-wrapper {
		margin: auto 15px auto 0;
		min-width: 57px;
		width: 57px;
		max-width: 57px;
		height: 57px;
		max-height: 57px;
		object-fit: cover;
	}
`;

export const NavLinks = styled.ul`
	margin: 0;
	min-width: 300px;
	height: 100%;
	list-style-type: none;
	display: flex;
	justify-content: center;
	align-items: flex-end;

	@media (max-width: 800px) {
		display: ${(props) => (props.toggle ? 'flex' : 'none')};
		margin-top: 67px;
		position: fixed;
		flex-direction: column;
		justify-content: space-between;
		flex-grow: 1;
		background-color: var(--background-color);
		width: 100%;
		height: 100vh;
		z-index: 4;
		padding: 100px 0;
	}

	> li {
		width: 140px;
		text-align: center;

		@media (max-width: 800px) {
			width: 100%;
			height: 100%;
			font-size: 30px;
			margin-bottom: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
	}
`;

export const Hamburger = styled.div`
	position: absolute;
	font-size: 40px;
	top: 15px;
	left: 30px;

	@media (min-width: 800px) {
		display: none;
	}
`;
