import styled from 'styled-components';

export const PortfolioWrapper = styled.div`
	padding: 30px 0;
	width: 100%;
	max-width: 1200px;
	min-height: 250px;
	height: 100%;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-template-rows: auto;
	grid-gap: 2em;
	align-items: center;

	@media (max-width: 500px) {
		grid-template-columns: auto;
		grid-template-rows: auto;
	}
`;
